import * as React from 'react'
import styled from "styled-components"
import Navbar from './navbar'

const Container = styled.div`
  margin: 2rem auto;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Layout = ({ pageTitle, children }) => {
  return (
    <Container>
      <title>{pageTitle}</title>
      <Navbar />
      <main>
        <h1>{pageTitle}</h1>
        {children}
      </main>
    </Container>
  )
}

export default Layout