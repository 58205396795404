import React from 'react';
import { Link } from 'gatsby'
import styled from "styled-components"

const MenuBar = styled.nav`
  width: 100%;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .menu-item {
    list-style-type: none;
    padding: 0 1.5em;
    text-transform: uppercase;
    font-weight: bold;
    a {
      text-decoration: none;
      &:hover {
        color: #a71ea7;
      }
    }
  }
`

const navbar = () => {
  return (
    <MenuBar>
      <ul>
        <li className="menu-item"><Link to="/">Home</Link></li>
        <li className="menu-item"><Link to="/about">About</Link></li>
      </ul>
    </MenuBar>
  );
}

export default navbar;
